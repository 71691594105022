import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TextField } from '@material-ui/core'
import {
    KeyboardDatePicker,
} from '@material-ui/pickers'

import { BaseForm } from './BaseForm'
import isValidSSO from '../../utils/isValidSSO'
import formStyles from './utils/formStyles'
import { ScheduleType } from '../../utils/schedule-types'



const ScheduleTemporaryUpdate = ({
    disabled = false,
    onSubmit,
    onChange,
    values,
    scheduleType = ScheduleType.daily_exceptions
}) => {
    const [validationError, setValidationError] = useState()
    const [ssoError, setSsoError] = useState(false)

    const classes = formStyles()

    const onTextFieldChange = ({ target: { id, value } }) => {
        onChange(id, value)
        if (!isValidSSO(value)) {
            setSsoError(true)
            return
        }

        setSsoError(false)
    }
    const handleOnSubmit = (e) => {
        e.preventDefault()
        onSubmit();
    }

    const isComplete = scheduleType == ScheduleType.daily_exceptions ? (values?.scheduled_effective_date && values?.scheduled_end_date) : 
                                        (scheduleType == ScheduleType.add_to_wave || scheduleType == ScheduleType.deactivate) ? (values?.scheduled_effective_date) : false
    
    return (
        <BaseForm
            onSubmit={handleOnSubmit}
            disabled={[!isComplete, disabled, ssoError, validationError, !isValidSSO(values?.sso_to_update)].some(x => x)}
            scheduleType={scheduleType}
        >
            <>
                <TextField
                    onChange={onTextFieldChange}
                    error={ssoError}
                    helperText={ssoError ? 'You must enter a valid SSO' : ' '}
                    id="sso_to_update"
                    label="SSO"
                    type="search"
                    InputProps={{
                        classes: {
                            notchedOutline: classes.notchedOutline,
                        },
                    }}
                />
                <KeyboardDatePicker
                    disablePast
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id= "enabled-date-picker-inline"
                    label={scheduleType == ScheduleType.deactivate ? 'Badge Disabled Date' : 'Badge Enabled Date'} 
                    value={values?.scheduled_effective_date}
                    onChange={(value) => onChange(
                        'scheduled_effective_date',
                        moment(value),
                    )}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                {
                    scheduleType == ScheduleType.daily_exceptions && (
                        <KeyboardDatePicker
                        initialFocusedDate={values?.scheduled_end_date}
                        minDate={moment(values?.scheduled_effective_date).add(1, 'days')}
                        maxDate={moment(values?.scheduled_effective_date).add(14, 'days')}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="disable-date-picker-inline"
                        label="Badge Disabled Date"
                        value={values?.scheduled_end_date}
                        onError={setValidationError}
                        onChange={(value) => onChange(
                            'scheduled_end_date',
                            moment(value),
                        )}
                        minDateMessage="This date must come after the Enabled Date"
                        maxDateMessage="Daily exceptions are limited to 14 days"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    )
                }
            </>
        </BaseForm>
    )
}

ScheduleTemporaryUpdate.propTypes = {
    pending: PropTypes.bool,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    values: PropTypes.shape({
        disabledDate: PropTypes.symbol,
        enabledDate: PropTypes.symbol,
    })
}

export default ScheduleTemporaryUpdate
