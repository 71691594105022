import React, { useState, useMemo, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Delete from '@material-ui/icons/Delete'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import { DropzoneArea } from 'material-ui-dropzone'
import GetAppIcon from '@material-ui/icons/GetApp'

import NbcuButton from '../common/nbcu-button'

import { ExcelRenderer } from 'react-excel-renderer'
import parse from '../../utils/parser'

import useUser from '../../hooks/useUser'
import { scheduleStatusUpdates } from '../../services/schedule-status-update'
import convertSpreadSheetToUpdateRequests from './convertSpreadSheetToObject'

import styles from './styles.module.css'
import ScheduleSubmissionReviewer from '../ScheduleSubmissionReviewer'

import xlsx from 'xlsx'
import moment from 'moment'

const defaultAcceptedFiles = [
  '',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  '.csv',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
]

const useStyles = makeStyles((theme) => ({
  submitButton: {
    width: '30%',
  },
  delete: {
    color: 'red',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '30px',
  },
  modalTitle: {
    '& h1': {
      fontSize: '16px',
      margin: 0,
    },
    '& h2': {
      fontSize: '12px',
      margin: 0,
    },
  },
  bulkErrors: {
    marginTop: '32px',
  },
  modalTitleFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

function ScheduleStatusUpdateBulk({
  updateType = 'addToWave',
  deactivateAll = false,
  enableAll = false,
  requiredFields = [],
}) {
  const { username, sessionToken } = useUser()
  const createdBy = username
  const classes = useStyles()

  const [acceptedFiles, setAcceptedFiles] = useState(defaultAcceptedFiles)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [pending, setPending] = useState(false)
  const [updateRequests, setUpdateRequests] = useState([])
  const [scheduleSubmissionResults, setScheduleSubmissionResults] = useState([])
  const [bulkFileName, setBulkFileName] = useState('')

  //tech debt: psuedo code for refactor [Please nuke everything below this psuedo code]
  // const [worksheet, setWorksheet, isWorksheetValid] = useWorksheetMachine() //should handle both csv, and excel files

  // const handleDrop = async file => {
  //   await setWorksheet(file)
  //   if(isWorksheetValid) {
  //     const { response: data, error } = await scheduleStatusUpdates(worksheet)
  //     if(error) {
  //       setAlert(withType)
  //     }

  //     setScheduleSubmissionResults(data)
  //   }
  // }



//tech debt: what in the world is this
  useEffect(() => {
    if (updateRequests.length) {
      setAcceptedFiles(['application/test'])
    } else {
      setAcceptedFiles(defaultAcceptedFiles)
    }
  }, [updateRequests])


  //tech debt: file.type in ['types'] reafactor into worksheet machine (idc what you call it)
  const fileIsXLSX = (fileObj) => {
    return (
      fileObj.type === 'application/vnd.ms-excel' ||
      fileObj.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setErrorMsg('')
    setSuccessMsg('')
    // remove the "tableData" property that gets added
    let filteredUpdateRequests = updateRequests.map((x) => {
      delete x.tableData
      return {
        ...x,
        update_type: updateType == 'addToWave' ? 'permanent' :
                     updateType == 'dailyException' ? 'temporary' :
                     updateType == 'disable' ? 'permanent' : 'unknown'
      };
    });
    setBulkFileName('');
    setUpdateRequests([]);

    try {
      const filteredUpdateRequestsWithErrors = filteredUpdateRequests?.filter(updateRequest => {
        if(updateType == 'dailyException') {
          return !updateRequest?.scheduled_effective_date || !updateRequest?.scheduled_end_date || !updateRequest?.sso_to_update || moment(updateRequest.scheduled_effective_date).isBefore(moment(), 'day') ||  moment(updateRequest.scheduled_end_date).isBefore(moment(), 'day')
        } else {
          return !updateRequest?.scheduled_effective_date || !updateRequest?.sso_to_update || moment(updateRequest.scheduled_effective_date).isBefore(moment(), 'day')
        }
      }) || null

      if (filteredUpdateRequestsWithErrors?.length != 0) {
         setErrorMsg(new Error(
          'Errors are present in this file. ' +
          'Please ensure that the requested file matches the upload template and try again.'
      ))
        return
      }
    } catch(err) {
      setErrorMsg(err)
      return
    }
    if (deactivateAll) {
      filteredUpdateRequests = filteredUpdateRequests.map((x) => ({
        ...x,
        enabled_date: null,
        disabled_date: x.scheduled_effective_date,
      }))
    }

    if (enableAll) {
      filteredUpdateRequests = filteredUpdateRequests.map((x) => ({
        ...x,
        badge_status: 'enabled',
      }))
    }

    updateStatus(filteredUpdateRequests)
  }

  const updateStatus = async (updateRequests) => {
    setPending(true)
    updateRequests = updateRequests.map((updateRequest) => {
      return { ...updateRequest, bulk_file_name: bulkFileName }
    })

    const { error, cancelPrevQuery, result=[] } = await scheduleStatusUpdates(
      createdBy,
      updateRequests,
      sessionToken
    )
    setPending(false)

    if (cancelPrevQuery) return
    console.log('res', result)
    if (error) {
      setErrorMsg({
        type: 'Error',
        message: error?.message
      })
      setSuccessMsg('')
    } else {
      //tech debt, this can be cleaned up now.
      setScheduleSubmissionResults(result?.map((submission) => {
        const notices = []
        const warnings = [submission?.warning]
        const errors =  [submission?.error]

        if (submission?.modal_settings && submission?.modal_settings?.message) {
          // warnings.push(submission.modal_settings.message)
          warnings.push({
            short_desc: `${submission.modal_settings?.message?.short_desc} Submit individually to edit dates.`
          })
        }

        warnings.map(noticeType => {
            noticeType && notices.push(noticeType)
        })
        errors.map(noticeType => {
            noticeType && notices.push(noticeType)
        })

        return {...submission, notices}

    })?.sort((sub, sub2) => {
        const { notices=[] } = sub
        const [notice1=''] = notices;

        const { notices:notices2=[] } = sub2
        const [notice2=''] = notices2;
        console.log('notice1', notice1)
        console.log('notice2', notice2)
        if (notice1 > notice2) {
            return -1
        } else if (notice1  < notice2) {
            return 1
        }

        return 0;


    }) || [])
      setErrorMsg('');
      // setSuccessMsg(`Successfully uploaded records`);
    }
  }

  const handleOnDrop = async (file) => {
    setBulkFileName('')
    setUpdateRequests([])
    setErrorMsg('')
    setSuccessMsg('')
    const fileObj = file[0]
    if (!fileObj) {
      console.warn('no spreadsheet file uploaded')
      // setErrorMsg("No file uploaded!");
      return false
    }

    const extension = fileObj.name.split('.').pop().toLowerCase()

    const isCsv = extension === 'csv'
    const isXlsx = extension === 'xlsx' || fileIsXLSX(fileObj)

    if (!isCsv && !isXlsx) {
      return
    }

    try {
      const results = isCsv
        ? await parse(fileObj)
        : await ExcelRenderer(fileObj)
      const spreadSheetData = isCsv ? results['data'] : results['rows']
      const updateRequests = convertSpreadSheetToUpdateRequests(
        spreadSheetData,
        createdBy,
        requiredFields
      )
      setBulkFileName(fileObj.name)
      setUpdateRequests(updateRequests)
    } catch (err) {
      setErrorMsg(err)
    }
  }

  const handleOnRemoveFile = (data) => {
    setErrorMsg('')
    setSuccessMsg('')
    setBulkFileName('')
    setUpdateRequests([])
  }

  const downloadAsset = useMemo(
    () =>
      updateType === 'disable'
        ? '/assets/Disable_Bulk_Template.xlsx'
        : updateType === 'dailyException'
        ? '/assets/Daily_Exception_Bulk_Template.xlsx'
        : '/assets/Add_to_Wave_Bulk_Template.xlsx',
    [updateType]
  )

  const downloadErrors = () => {
    const wb = xlsx.utils.book_new()
    const worksheetName = 'Cleared worker database'
    let worksheetData = []

    if (updateType == 'disable') {
      worksheetData = [
        ['SSO', 'Disable Date', 'Error'],
        ...scheduleSubmissionResults
          .filter((s) => !s.scheduled)
          .map((submission) => [
            submission?.sso_to_update,
            submission?.scheduled_end_date,
            (submission?.errors?.length && submission?.errors[0]) ||
              (submission?.warnings?.length && submission?.warnings[0]) ||
              (submission?.modal_settings &&
                submission?.modal_settings?.message),
          ]),
      ]
    } else if (updateType == 'dailyException') {
      worksheetData = [
        ['SSO', 'Activation Date', 'Disable Date', 'Error'],
        ...scheduleSubmissionResults
          .filter((s) => !s.scheduled)
          .map((submission) => [
            submission?.sso_to_update,
            submission?.scheduled_effective_date,
            submission?.scheduled_end_date,
            (submission?.errors?.length && submission?.errors[0]) ||
              (submission?.warnings?.length && submission?.warnings[0]) ||
              (submission?.modal_settings &&
                submission?.modal_settings?.message) ||
                (submission?.validation.short_desc),
          ]),
      ]
    } else {
      worksheetData = [
        ['SSO', 'Activation Date', 'Error'],
        ...scheduleSubmissionResults
          .filter((s) => !s.scheduled)
          .map((submission) => [
            submission?.sso_to_update,
            submission?.scheduled_effective_date,
            (submission?.validation.short_desc) ||
            (submission?.errors?.length && submission?.errors[0]) ||
              (submission?.warnings?.length && submission?.warnings[0]) ||
              (submission?.modal_settings &&
                submission?.modal_settings?.message),
          ]),
      ]
    }

    const ws = xlsx.utils.aoa_to_sheet(worksheetData)
    xlsx.utils.book_append_sheet(wb, ws, worksheetName)
    xlsx.writeFile(wb, 'error-output.xlsx')
  }

  return (
    <div>
      <form
        onSubmit={onSubmit}
        className={classes.root}
        noValidate
        autoComplete="off"
      >
        <a
          href={process.env.PUBLIC_URL + downloadAsset}
          target="_blank"
          rel="noopener noreferrer"
          download
          className={styles.downloadTemplateBtn}
        >
          <p>Download Batch Template</p>
          <GetAppIcon className={styles.downloadIcon} />
        </a>
        <div className={styles.dropzone} data-testid="bulk-dropzone">
          <DropzoneArea
            onDropRejected={(rej) => {
              console.log('rejected', rej)
              if (updateRequests.length || pending) {
                setErrorMsg(
                  new Error(
                    'Only one file can be uploaded at a time, please delete the old file first'
                  )
                )
              }
            }}
            multiple={false}
            acceptedFiles={acceptedFiles}
            onChange={handleOnDrop}
            onDelete={handleOnRemoveFile}
            showFileNames
            dropzoneText={
              <div className={styles.dropzoneBody}>
                Drop CSV, XLS, XLSX file here or
                <button
                  className={styles.uploadBtn}
                  onClick={(e) => e.preventDefault()}
                >
                  Upload
                </button>
              </div>
            }
            showAlerts={true}
            filesLimit={1}
            showAlerts={false}
          />
        </div>

        <div className={styles.actionGroup}>
          <NbcuButton
            disabled={pending || updateRequests.length === 0}
            data-testid="submit-button"
          >
            Schedule Update
          </NbcuButton>
        </div>
        {pending && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}

        {errorMsg && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong>{errorMsg.message}</strong>
          </Alert>
        )}
        {successMsg && (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            <strong>{successMsg}</strong>
          </Alert>
        )}
      </form>
      <Dialog
        open={scheduleSubmissionResults?.length}
        onClose={() => setScheduleSubmissionResults(null)}
        disableBackdropClick={true}
        scroll="paper"
      >
        <DialogTitle className={classes.modalTitle}>
          <header>
            <div className={classes.modalTitleFlex}>
              <h1>Bulk Upload Results</h1>
              <Tooltip title="Close">
                <IconButton
                  onClick={() => setScheduleSubmissionResults(null)}
                  data-testid="group-toggle"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
            <section>
              <div>
                <span>
                  {
                    scheduleSubmissionResults?.filter((r) => r.scheduled)
                      ?.length
                  }
                </span>{' '}
                SSOs successful submitted
              </div>
              <div>
                <span>
                  {
                    scheduleSubmissionResults?.filter((r) => !r.scheduled)
                      ?.length
                  }
                </span>{' '}
                SSOs had errors and were not submitted.
              </div>
            </section>
          </header>
          <section>
            <div className={`${classes.modalTitleFlex} ${classes.bulkErrors}`}>
              <div>
                <h1>Bulk Upload Errors</h1>
                <h2>
                  Download this list as an excel file or copy and paste for your
                  records.
                </h2>
              </div>
              <Button onClick={downloadErrors}>Download file</Button>
            </div>
          </section>
        </DialogTitle>
        <DialogContent>
          <ScheduleSubmissionReviewer
            submissions={scheduleSubmissionResults}
            onClose={() => setScheduleSubmissionResults(null)}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ScheduleStatusUpdateBulk
