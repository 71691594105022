import React from 'react'
import {
    CircularProgress,
    Divider,
} from '@material-ui/core'

import { ScheduleTemporaryForm } from './Forms'
import useStatusUpdate from '../hooks/useStatusUpdate'
import Modal, { IsolatedModal } from './Modals'
import AlertContainer from './common/AlertContainer'
import BadgeExceptionBatchUpdate from '../containers/DailyException/DailyExceptionBatchUpdate'
import { ScheduleType } from '../utils/schedule-types'
import BatchUpdate from './ScheduleStatusUpdateBulk'
import Typography from '@material-ui/core/Typography'
import styles from './schedule-badge-status.module.css'

export default ({ scheduleType = ScheduleType.daily_exceptions }) => {
    const {
        submitSchedule,
        isLoading,
        setField,
        fields,
        alert,
        modal,
        setModal,
    } = useStatusUpdate()

    const update_type = scheduleType == ScheduleType.daily_exceptions ? 'temporary' :
                        scheduleType == ScheduleType.add_to_wave ? 'permanent' : 
                        scheduleType == ScheduleType.deactivate ? 'permanent' : 'unknown'
    const requestSchedule = () => submitSchedule([{
        ...fields,
        badge_status:  scheduleType == ScheduleType.deactivate ? 'disabled' : 'enabled',
        update_type
    }])
    
    const confirmOverride = () => submitSchedule([{
        ...fields,
        badge_status: scheduleType == ScheduleType.deactivate ? 'disabled' : 'enabled',
        update_type,
        override_pending_activity: true 
    }])

    const submission_type = scheduleType == ScheduleType.daily_exceptions ? 'Daily Exception' :
    scheduleType == ScheduleType.add_to_wave ? 'Add to Wave' : 
    scheduleType == ScheduleType.deactivate ? 'deactivation' : 'unknown'

    const submission_text = scheduleType == ScheduleType.daily_exceptions ? 'Ensure that each SSO has Enable and Disabled dates in future.' :
    scheduleType == ScheduleType.add_to_wave ? 'Ensure that each SSO has activation dates in future.' : 
    scheduleType == ScheduleType.deactivate ? 'Ensure that each SSO has disable dates in future.' : 'Ensure that each SSO has dates in future.'

    return (
        <>
            <Modal
                type={modal?.type}
                message={modal?.message}
                open={!!modal?.type}
                onConfirm={confirmOverride}
                onClose={() => setModal(false)}
            />
            <ScheduleTemporaryForm
                values={fields} 
                onChange={setField}
                disabled={isLoading}
                onSubmit={requestSchedule}
                scheduleType={scheduleType}
            />
            <AlertContainer alert={alert}/>
            {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
                    <CircularProgress />
                </div>
            )}

            <Divider style={{ margin: 10 }}/>
            <h2 className={styles.headerLabel}>Batch Update</h2>
            <Typography className={styles.description} variant="body1">
                <span>
                    Upload a spreadsheet of employees for {submission_type} to quickly set
                    enabled and disable dates for a large number of SSOs at once.
                </span>
                <ol>
                    <li>
                        Download the template for each upload.{' '}
                        <strong>(Do not create your own.)</strong>
                    </li>
                    <li>Do not edit the first row of the template. </li>
                    <li>
                        <strong>Do not insert additional columns.</strong>
                    </li>
                    <li>
                        <strong>
                            {submission_text}
                        </strong>
                    </li>
                    <li>
                        <strong>Ensure there are no duplicates.</strong>
                    </li>
                    <li>
                        <strong>Limit of 150 rows per file/upload.</strong>
                    </li>
                </ol>
            </Typography>
            { scheduleType == ScheduleType.daily_exceptions && <BadgeExceptionBatchUpdate /> }
            { scheduleType == ScheduleType.add_to_wave && (
                <BatchUpdate
                      updateType = 'addToWave'
                      requiredFields={['sso_to_update', 'enabled_date']} enableAll={true} />
            )}
            { scheduleType == ScheduleType.deactivate && (
                <BatchUpdate
                      updateType = 'disable'
                      deactivateAll
                      requiredFields={['sso_to_update', 'disabled_date']}
                    />
            ) }
        </>
    )
}