import React from 'react'
import Moment from 'react-moment'
import { Alert, AlertTitle } from '@material-ui/lab'

import { defaultField } from '../../utils'

import styles from './user-results.module.css'
import moment from "moment";

export default ({ users = [], query, pending, hasPreexistingError }) => {
  return (
  <>
    {query !== '' && !pending && (
      <>
         <div className={styles.resultsSummary} data-testid="results-summary">
          <h3 className={styles.queryDisplay}>Search Results for "{query}"</h3>
          <div className={styles.resultsCount}>
            TOTAL RESULTS: {users ? users.length : 0}
          </div>
        </div>
        {users.length === 0 && !hasPreexistingError && (
          <Alert severity="warning">
            <AlertTitle>Notice</AlertTitle>
            <strong>
              Your search failed to return any results. Please check the SSO and
              try again
            </strong>
          </Alert>
        )}
        {users && users.length > 0 && (
          <table className={styles.table} aria-label="customized table">
            <thead className={styles.tableHead}>
              <tr>
                <th><b>NAME</b></th>
                <th><b>SSO</b></th>
                <th><b>CURRENT<br />BADGE STATUS</b></th>
                <th><b>DAILY EXCEPTION<br />DATE RANGE</b></th>
                <th><b>WAVE BADGE<br />ACTIVATION DATE</b></th>
                <th className={styles.thCenter}><b>ADDED TO<br />WAVE REPORT</b></th>
                <th className={styles.thCenter}><b>ADDED TO<br />LMS</b></th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {users
                .reduce(
                  (a, c) =>
                    !!a.find((x) => x?.employee?.sso == c?.employee?.sso)
                      ? a
                      : [...a, c],
                  []
                )
                .map((user) => {
                  const add_to_wave_schedules = user?.employee?.badge_schedule?.add_to_wave_schedules || []
                  const daily_exception_schedules = user?.employee?.badge_schedule?.daily_exception_schedules || []

                  const parsedATW = add_to_wave_schedules.filter((elem, index) => {
                    const scheduled_date = moment(elem.effective_date)
                    const current_date = moment.now()
                    return !scheduled_date.isBefore(current_date, 'day') && elem.update_type_id == 2 && !elem?.event_timestamp
                  }).map((elem, index) => (
                    <li className={styles.statusItem} key={index}>
                      <Moment format="MM/DD/YYYY">
                        {elem.effective_date}
                      </Moment>
                    </li>
                  ))

                  const parsedDailyExceptions = daily_exception_schedules.filter((elem, index) => elem.update_type_id == 1).filter(elem => {
                    const scheduled_date = moment(elem?.scheduled_end_date)
                    const current_date = moment.now()
                    return !scheduled_date.isBefore(current_date, 'day') && !elem?.deactivation_time
                  }).map((elem, index) => {
                    const first_scheduled_date = moment(elem.effective_date)
                    const scheduled_end_date = moment(elem?.scheduled_end_date)
                    const current_date = moment.now()
                    const isStartDateInPast = first_scheduled_date.isBefore(current_date, 'day')
                    const isEndDateInPast = scheduled_end_date.isBefore(current_date, 'day')
                    return (
                      <li className={styles.statusItem} key={index}>
                        <Moment format="MM/DD/YYYY" className={`${isStartDateInPast ? styles.disabledDate : ''}`}>
                          {elem?.effective_date}
                        </Moment>{' '}
                        - <Moment format = "MM/DD/YYYY" className={`${isEndDateInPast ? styles.disabledDate : ''}`} >
                        {elem?.scheduled_end_date}
                      </Moment>
                      </li>
                    )
                  })

                  return (
                    <tr className={checkIsActive(user) ? '' : styles.inActiveUser} key={user?.employee?.sso}>
                      <td>
                        {
                          {
                            [true]: 'Unknown',
                            [!!user?.employee?.full_name]: user?.employee?.full_name,
                            [!!user?.employee?.safe_details?.firstname]: `${user?.employee?.safe_details?.firstname} ${user?.employee?.safe_details?.lastname}`,
                          }.true
                        }
                      </td>
                      <td>
                        {defaultField(user?.employee?.sso, 'Unknown')}
                      </td>
                      <td className={styles.status}>
                        {defaultField(summarizeStatus(user), 'Unknown')}
                      </td>
                      <td className={styles.tdHighlight}>
                        {parsedDailyExceptions?.length ? parsedDailyExceptions : <span className={styles.emptyTd}>-</span>}
                      </td>
                      <td className={styles.tdHighlight}>
                        { parsedATW?.length ? parsedATW :  <span className={styles.emptyTd}>-</span> }
                      </td>
                      <td className={styles.tdHighlight}>
                        {
                          add_to_wave_schedules?.filter((elem, index) => {
                            return elem?.update_type_id == 2 && elem?.access_enabled

                          })?.length ? 'YES' : <span className={styles.emptyTd}>-</span>
                        }
                      </td>
                      <td className={styles.tdHighlight}>
                        <span title={
                          user?.employee?.lms_course_completed ? 'Course Completed' :
                          user?.employee?.lms_course_assigned ? 'Course Assigned' : 'Course Unassigned'
                        }>
                          { user?.employee?.lms_course_assigned || user?.employee?.lms_course_completed ? 'YES' : 'NO'}
                        </span>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        )}
      </>
    )}
  </>
)
}

function pairDailyExceptions(schedules = []) {
  return schedules.reduce((pairedSchedules, schedule, index, source) => {
    if(index % 2 == 0) {
      pairedSchedules.push([schedule, source[index+1]])
    }
    return pairedSchedules
  }, [])
}

// SAFE returns cardstatus of 'DISABLED', 'Disabled', 'ACTIVE', or 'Active'
function summarizeStatus(user) {

 if(!checkIsActive(user)) {
   return 'Inactive Employee';
 }

  const badges = user?.employee?.safe_details?.badges

  if (
    !badges ||
    !badges.length ||
    !badges.some((x) =>
      ['active', 'disabled'].includes(x.cardstatus?.trim().toLowerCase())
    )
  ) {
    return 'Badge not found in safe'
  }

  for (const badge of badges) {
    console.debug('badge', badge)
  }

  if (badges.every((x) => x.cardstatus?.trim().toLowerCase() === 'active')) {
    return 'active'
  }

  return 'disabled'
}

function checkIsActive(user) {
  return user?.employee?.status === 'A' || user?.employee?.status === 'U';
}
