import React from 'react'

import UserSearch from '../components/UserSearch'

import ScheduleBadgeStatus from '../components/ScheduleBadgeStatus'
import { ScheduleType } from '../utils/schedule-types'

const AddToWave = () => <ScheduleBadgeStatus scheduleType={ScheduleType.add_to_wave}/>
const Deactivate = () => <ScheduleBadgeStatus scheduleType={ScheduleType.deactivate}/>

export default [
  {
    label: 'Search',
    path: '/user-search',
    header: 'Employee Search',
    description: 'Search employees or contractors by SSO or Email.',
    component: UserSearch,
  },
  {
    label: 'Add to Wave',
    header: 'Individual Update',
    path: '/schedule-status-update',
    description:
      'Permanently grant an employee/ contractor access by identifying them as part of a formal returning wave beginning on a specified date.',
    component: AddToWave,
  },
  {
    header: 'Individual Update',
    label: 'Daily Exception',
    path: '/schedule-status-update-temp',
    description:
      'Temporarily grant an employee/ contractor access. Access can be submitted for a 24-hour period or more.',
    component: ScheduleBadgeStatus,
  },
  {
    header: 'Deactivate',
    label: 'Badge Deactivation',
    path: '/schedule-deactivate',
    description: 'Permanently deactivate employee/ contractor access.',
    component: Deactivate,
  }
]
